import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../../../components/seo"
import {
  CardHorizontalFriends,
  CardVerticalFriends,
} from "../../../components/Cards"

import useFriends from "../../../utils/useFriends"

const Friends = () => {
  const data = useFriends()

  console.log(data)

  return (
    <>
      <div classsName="relative right-0 left-0 overflow-x-hidden">
        <div className="absolute top-0 -left-12 lg:bottom-0 lg:right-0">
          <StaticImage
            height={140}
            src="../../../images/BackgroundElements/JuicyFriends/BananaRodajas-01.png"
            placeholder="tracedSVG"
            alt="banana"
          />
        </div>

        <Seo title="Friends" />
        <div className="relative flex flex-col gap-8">
          <h1 className="lg:hidden mobile-title pt-8 text-center">
            Juicy Friends
          </h1>
          <h1 className="hidden lg:block desktop-title text-center pb-2 pt-8">
            Juicy Friends
          </h1>
          <div className="max-w-mobile pl-2 lg:max-w-6xl lg:text-center mobile-paragraph lg:desktop-paragraph mx-auto">
            <p>
              They say you have three types of friends: for a reason, for a
              season and for a lifetime. Meet mine! Peeps who are walking their
              walk , not talking the talk!! Check out their work, their art, and
              their business offerings.
              <br />
              They all form my 4 Pillar Posse, so to speak!!!!
            </p>
          </div>
        </div>
        <div className="py-8">
          <div className="xl:hidden overflow-x-scroll snap-x flex items-center mx-auto gap-8 py-4">
            <div className="mx-auto space-y-8 z-20">
              {data.map(i => (
                <CardVerticalFriends
                  title={i.title}
                  heading={i.heading}
                  image={i.image}
                  body={i.body}
                  link={i.link}
                  slug={i.slug}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="pb-16 ">
          <div className="hidden  max-w-7xl xl:grid grid-cols-2 place-content-center gap-16 mx-auto px-2">
            {data.map(i => (
              <CardHorizontalFriends
                title={i.title}
                heading={i.heading}
                image={i.image}
                body={i.body}
                link={i.link}
                slug={i.slug}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="fixed -bottom-20 -right-20">
        <StaticImage
          height={323}
          width={234}
          src="../../../images/BackgroundElements/JuicyFriends/BananaSplash-01.png"
          placeholder="tracedSVG"
          alt="banana"
        />
      </div>
    </>
  )
}

export default Friends
