import { useStaticQuery, graphql } from "gatsby"

const useFriends = () => {
  const data = useStaticQuery(
    graphql`
      query MyQueryFriends {
        allContentfulFriends {
          nodes {
            title
            slug
            headingCard
            image {
              gatsbyImageData(placeholder: TRACED_SVG, height: 262)
            }
            cardBody {
              cardBody
            }
            link
          }
        }
      }
    `
  )
  return data.allContentfulFriends.nodes.map(i => ({
    title: i.title,
    heading: i.headingCard,
    body: i.cardBody.cardBody,
    image: i.image.gatsbyImageData,
    link: i.link,
    slug: i.slug,
  }))
}

export default useFriends
