import React from "react";

import Layout from "../layout/Layout";

import Seo from "../components/seo";
import Friends from "../components/sections/Friends";

const FriendsPage = () => {
  return (
    <Layout>
      <Seo title="About" />
      <Friends />
    </Layout>
  );
};

export default FriendsPage;
